// types
import { createSlice } from '@reduxjs/toolkit';
// import {loadAuth, unloadAuth} from '../../firebase';

// initial state
const initialState = {
  ucs: [],
  users: [],
};

// ==============================|| SLICE - MENU ||============================== //

const admin = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    ucListSuccess(state, action) {
      console.log('this is our reducer', state, action);
      state.ucs = [ ...action.payload ];
    },

    userListSuccess(state, action) {
      console.log('this is the user success', state, action);
      state.users = [ ...action.payload ];
    },
    //
    // loginRequest(state) {
    //   state.isLoggingIn = true;
    //   loadAuth();
    // },
    //
    // logoutRequest(state) {
    //   state.isLoggingOut = true;
    //   unloadAuth();
    // }
  }
});

export default admin.reducer;

export const { ucListSuccess, userListSuccess } = admin.actions;
