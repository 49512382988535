import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';

// render - dashboard
const UtilityCoeff = Loadable(lazy(() => import('pages/admin/UtilityCoeff')));
const Users = Loadable(lazy(() => import('pages/admin/Users')));

// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = {
  path: 'admin',
  children: [
    {
      path: 'utility-coeff',
      element: <UtilityCoeff/>
    },
    {
      path: 'users',
      element: <Users/>
    },
  ]
};

export default AdminRoutes;
