import { Bytes } from 'firebase/firestore';
import { deflate, inflate } from 'pako';

const blobToStorage = (fullUc) => {
  const uc = JSON.stringify(fullUc)
  const output = deflate(uc);
  return Bytes.fromUint8Array(output);
}

const blobFromStorage = (compressed) => {
  const ucStr = compressed.toUint8Array();
  return JSON.parse(inflate(ucStr, {to: 'string'}));
}

const ucPath = 'utilityCoefficients';

const previewUc = (uc) => {
  const previewVals = uc[0].slice(0,5)
    .map((val) => Number(val).toLocaleString(undefined, {style: 'decimal', maximumFractionDigits: 2}));
  return [...previewVals, '...'].join(', ');
}

export { blobToStorage, blobFromStorage, ucPath, previewUc };