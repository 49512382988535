// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  dataLoaded: false,
  features: [],
  featuresMap: {},
  subFeatures: [],
  subFeaturesMap: {},
  subFeaturesByFeature: {},
  uc: [],
  fiRange: [],
  fiDistribution: [],
  fiAverage: [],
  logitUc: [],
  prefShare: [],
  prefShareAverage: [],
  utilityAverage: [],
};

// ==============================|| SLICE - MENU ||============================== //

const uc = createSlice({
  name: 'uc',
  initialState,
  reducers: {
    loadData(state, action) {
      console.log('we have an attempt to load data', action.payload)
      state.dataLoaded = true;
      state.features = [...action.payload.features];
      state.featuresMap = {...action.payload.featuresMap};
      state.subFeatures = [...action.payload.subFeatures];
      state.subFeaturesMap = {...action.payload.subFeaturesMap};
      state.subFeaturesByFeature = {...action.payload.subFeaturesByFeature};
      state.uc = [...action.payload.uc];
      state.fiRange = [...action.payload.fiRange];
      state.fiDistribution = [...action.payload.fiDistribution];
      state.fiAverage = [...action.payload.fiAverage];
      state.logitUc = [...action.payload.logitUc];
      state.prefShare = [...action.payload.prefShare];
      state.prefShareAverage = [...action.payload.prefShareAverage];
      state.utilityAverage = [...action.payload.utilityAverage];
    },
  }
});

export default uc.reducer;

export const { loadData } = uc.actions;
