// types
import { createSlice } from '@reduxjs/toolkit';
import {createPid, updatePackage, updatePackageList} from '../../packages';

// First, create the thunk
// const fetchUserById = createAsyncThunk(
//   'packages/savePackage',
//   async (input, thunkAPI) => {
//     console.log('we have input thusly', input)
//     const response = await savePackage(input.user, input.package )
//     return response.data
//   }
// )

// initial state
const initialState = {
  packagesLoaded: false,
  packagesLoading: true,
  packages: {
    current: {}
  },
  packageList: {},
  stalePackageList: false,
  needsSaving: {},
};

// The packages look like this:
//  {
//    B: "B_1",
//    HIP: "HIP_2",
//    x4k: "x4k_1",
//  }

// ==============================|| SLICE - MENU ||============================== //

const packages = createSlice({
  name: 'packages',
  initialState,
  reducers: {
    loadPackages(state, action) {
      console.log('we have an attempt to load packages', action.payload)
      state.packagesLoaded = true;
      state.packagesLoading = false;
      state.packages = {...action.payload};
      return state;
    },
    loadingPackages(state) {
      state.packagesLoading = true;
      return state;
    },
    loadAllPackages(state, action) {
      state.packagesLoaded = true;
      state.packagesLoading = false;
      state.packages = { ...action.payload };
      return state;
    },
    loadPackage(state, action) {
      console.log('loading package', action.payload);
      const {name, pkg} = action.payload;
      state.packages[name] = {
        ...(state.packages[name] || {}),
        ...pkg
      };
      state.needsSaving[name] = true;
      return state;
    },
    savePackage(state, action) {
      const {user, name} = action.payload || {};
      if(state.needsSaving[name] && state.packages[name]) {
        console.log('package saving!', state, action);
        delete state.needsSaving[name];
        if(!state.packageList[name]) {
          state.packageList[name] = createPid();
          state.stalePackageList = true;
        }
        updatePackage(user, state.packages[name], state.packageList[name]).then(console.log);
      }
      return state;
    },
    saveAllPackages(state, action) {
      [...Object.keys(state.needsSaving)].forEach((name) => {
        delete state.needsSaving[name];
        if (state.packages[name]) {
          console.log('saving package!', state, name, action);
          if (!state.packageList[name]) {
            state.packageList[name] = createPid();
            state.stalePackageList = true;
          }
          updatePackage(action.payload, state.packages[name]).then(console.log);
        }
      });
      return state;
    },
    loadPackageList(state, action) {
      console.log('loading package list', action.payload)
      state.packageList = {...action.payload};
      state.stalePackageList = true;
      return state;
    },
    savePackageList(state, action) {
      if(state.stalePackageList) {
        state.stalePackageList = false;
        console.log('saving package list', action.payload)
        updatePackageList(action.payload?.user, state.packageList)
          .then((res) => console.log('updating package success', res))
          .catch((res) => console.log('updating package failure', res));
      }
      return state;
    },
  }
});

export default packages.reducer;

export const {
  loadPackages,
  loadingPackages,
  loadAllPackages,
  loadPackage,
  savePackage,
  saveAllPackages,
  loadPackageList,
  savePackageList,
} = packages.actions;
