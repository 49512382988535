// types
import { createSlice } from '@reduxjs/toolkit';
import {loadAuth, unloadAuth} from '../../firebase';

// initial state
const initialState = {
  admin: false,
  email: null,
  isLoggingIn: false,
  isLoggingOut: false,
  name: 'Login',
  plist: [],
  role: 'None',
  uid: null,
  ucDoc: 'all',
};

// ==============================|| SLICE - MENU ||============================== //

const account = createSlice({
  name: 'account',
  initialState,
  reducers: {
    loginSuccess(state, action) {
      console.log('this is our reducer', state, action);
      state.isLoggingIn = false;
      state.name = action.payload.name;
      state.uid = action.payload.uid;
      state.ucDoc = action.payload.ucDoc;
      state.email = action.payload.email;
      state.admin = action.payload.admin;
      state.role = action.payload.role;
      state.plist = action.payload.plist;
    },

    logoutSuccess(state) {
      state.isLoggingOut = false;
      state.name = 'Login';
      state.uid = state.email = null;
      state.ucDoc = 'all';
      state.admin = false;
      state.role = 'None';
      state.plist = [];
    },

    loginRequest(state) {
      state.isLoggingIn = true;
      loadAuth();
    },

    logoutRequest(state) {
      state.isLoggingOut = true;
      unloadAuth();
    }
  }
});

export default account.reducer;

export const { loginSuccess, logoutSuccess, loginRequest, logoutRequest } = account.actions;
