const ALL = 'all';
const ADMIN = 'admin';
const READ = 'read';

const visible = {
  all: () => true,
  admin: ({admin}) => admin,
  read: ({role}) => role === READ,
}

export {ALL, ADMIN, READ, visible};