import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

// import subRoutes
import ReportRoutes from './ReportRoutes';
import InputRoutes from './InputRoutes';
import AdminRoutes from './AdminRoutes';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const Simulator = Loadable(lazy(() => import('pages/simulator')));
const OverviewSummary = Loadable(lazy(() => import('pages/overviewSummary')));

// render - sample page
// const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// render - utilities
// const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
// const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout/>,
    children: [
        {
            path: '/',
            element: <DashboardDefault/>
        },
        {
            path: 'color',
            element: <Color/>
        },
        {
            path: 'overview',
            element: <OverviewSummary/>
        },
        {
            path: 'simulator',
            element: <Simulator/>
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault/>
                }
            ]
        },
        ReportRoutes,
        InputRoutes,
        AdminRoutes,
        // {
        //     path: 'sample-page',
        //     element: <SamplePage />
        // },
        // {
        //     path: 'shadow',
        //     element: <Shadow />
        // },
        // {
        //     path: 'typography',
        //     element: <Typography />
        // },
        {
            path: 'icons/ant',
            element: <AntIcons/>
        }
    ]
};

export default MainRoutes;
