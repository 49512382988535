import React, { useEffect } from 'react';
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import {useDispatch, useSelector} from 'react-redux';

import { setupAuth } from './firebase';
import { loadData } from './store/reducers/uc';
import data from './data';
import {getCosts} from './costs';
import {loadCosts} from './store/reducers/cost';
import {getCurrentPackage} from './packages';
import {loadPackages} from './store/reducers/packages';

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const getData = async (u) => {
      const receivedData = await data(u);
      dispatch(loadData(receivedData));
      const current = await getCurrentPackage(u, receivedData);
      dispatch(loadPackages({current}))
    }

    if(!user.uid) return;
    getData(user).then(() => console.log('Data Loaded'));
    getCosts(user).then((costs) => dispatch(loadCosts(costs)));
  }, [dispatch, user]);

  useEffect(() => {
    setupAuth(dispatch);
  })

  return (
  <ThemeCustomization>
    <ScrollTop>
      <Routes />
    </ScrollTop>
  </ThemeCustomization>
  );
}

export default App;