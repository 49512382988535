// assets
import { DashboardOutlined, HomeOutlined } from '@ant-design/icons';
import {ALL} from '../utils/pageView';

// icons
const icons = {
  DashboardOutlined,
  HomeOutlined,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const top = {
  id: 'group-top',
  title: '',
  visibility: ALL,
  type: 'group',
  children: [
    {
      id: 'overview',
      title: 'Overview Summary',
      type: 'item',
      url: '/overview',
      icon: icons.HomeOutlined,
      breadcrumbs: false
    },
    {
      id: 'simulator',
      title: 'Simulator',
      type: 'item',
      url: '/simulator',
      icon: icons.DashboardOutlined,
      breadcrumbs: false
    },
  ]
};

export default top;
