// assets
import { FilterOutlined, CreditCardOutlined, TableOutlined } from '@ant-design/icons';
import {ALL} from '../utils/pageView';

// icons
const icons = {
  FilterOutlined,
  CreditCardOutlined,
  TableOutlined,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const inputs = {
  id: 'group-inputs',
  title: 'Inputs',
  visibility: ALL,
  type: 'group',
  children: [
    {
      id: 'filters',
      title: 'Filters',
      type: 'item',
      url: '/inputs/filters',
      icon: icons.FilterOutlined,
      breadcrumbs: false
    },
    {
      id: 'cost-inputs',
      title: 'Cost Inputs',
      type: 'item',
      url: '/inputs/cost-inputs',
      icon: icons.CreditCardOutlined,
      breadcrumbs: false
    },
    {
      id: 'current',
      title: 'Current Benefit Offerings',
      type: 'item',
      url: '/inputs/current',
      icon: icons.TableOutlined,
      breadcrumbs: false
    }
  ]
};

export default inputs;
