const cfg = {
  apiKey: "AIzaSyCAhK9JfKiQeBeIzoWZwFCAE5veBkcabCg",
  authDomain: "benephitz-wonderlutz.firebaseapp.com",
  projectId: "benephitz-wonderlutz",
  storageBucket: "benephitz-wonderlutz.appspot.com",
  messagingSenderId: "1071107541270",
  appId: "1:1071107541270:web:9705e8a3bee1c81f8da837",
  measurementId: "G-4BPWB71VY6"
};

export default cfg;