export const defaultFeatures = [
  {featureCode: 'HIP', feature: 'Health Insurance Premium', numSubs: 10},
  {featureCode: 'HSA', feature: 'HSA Contribution', numSubs: 9},
  {featureCode: 'BP', feature: 'Base Pay', numSubs: 8},
  {featureCode: 'PTO', feature: 'Paid Time Off (PTO)', numSubs: 10},
  {featureCode: '4k', feature: '401k', numSubs: 7},
  {featureCode: 'WF', feature: 'Work Flexibility', numSubs: 3},
  {featureCode: 'DI', feature: 'Dental Insurance', numSubs: 3},
  {featureCode: 'WFP', feature: 'Wellbeing/Fitness Programs', numSubs: 8},
  {featureCode: 'TR', feature: 'Tuition Reimbursement', numSubs: 4},
  {featureCode: 'FPS', feature: 'Family Program / Services', numSubs: 7},
  {featureCode: 'LI', feature: 'Life Insurance', numSubs: 6},
  {featureCode: 'SD', feature: 'Short-term disability', numSubs: 3},
  {featureCode: 'LD', feature: 'Long-term disability', numSubs: 3},
  {featureCode: 'FL', feature: 'Paid Family Leave', numSubs: 5},
  {featureCode: 'OS', feature: 'On-site Services', numSubs: 10},
  {featureCode: 'B', feature: 'Performance Bonus', numSubs: 7},
  {featureCode: 'CSV', feature: 'Community Service / Volunteering', numSubs: 4},
  {featureCode: 'CD', feature: 'Career Development', numSubs: 5},
  {featureCode: 'LS', feature: 'Legal Services', numSubs: 3},
  {featureCode: 'VI', feature: 'Vision Insurance', numSubs: 4},
  {featureCode: 'EB', feature: 'Experience/Travel Bonus', numSubs: 5},
  {featureCode: 'HID', feature: 'Health Insurance Deductible', numSubs: 3},
]

export const defaultSubFeatures = [
  {levelCode: 'HIP_1', level: 'No changes to Health Insurance Premium'},
  {levelCode: 'HIP_2', level: '5% increase in Health Insurance Premium cost to employee'},
  {levelCode: 'HIP_3', level: '10% increase in Health Insurance Premium cost to employee'},
  {levelCode: 'HIP_4', level: '25% increase in Health Insurance Premium cost to employee'},
  {levelCode: 'HIP_5', level: '35% increase in Health Insurance Premium cost to employee'},
  {levelCode: 'HIP_6', level: '5% decrease in Health Insurance Premium cost to employee'},
  {levelCode: 'HIP_7', level: '10% decrease in Health Insurance Premium cost to employee'},
  {levelCode: 'HIP_8', level: '25% decrease in Health Insurance Premium cost to employee'},
  {levelCode: 'HIP_9', level: '35% decrease in Health Insurance Premium cost to employee'},
  {levelCode: 'HIP_10', level: '100% coverage of Health Insurance Premium (no cost to employee)'},
  {levelCode: 'HSA_1', level: 'No HSA contribution made by employer'},
  {levelCode: 'HSA_2', level: '$500 HSA contribution made by employer'},
  {levelCode: 'HSA_3', level: '$1,000 HSA contribution made by employer'},
  {levelCode: 'HSA_4', level: '$1,500 HSA contribution made by employer'},
  {levelCode: 'HSA_5', level: '$2,000 HSA contribution made by employer'},
  {levelCode: 'HSA_6', level: '$2,500 HSA contribution made by employer'},
  {levelCode: 'HSA_7', level: '$3,000 HSA contribution made by employer'},
  {levelCode: 'HSA_8', level: '$4,000 HSA contribution made by employer'},
  {levelCode: 'HSA_9', level: '$5,000 HSA contribution made by employer'},
  {levelCode: 'BP_1', level: 'No changes in base pay'},
  {levelCode: 'BP_2', level: '2.5% increase in base pay'},
  {levelCode: 'BP_3', level: '5% increase in base pay'},
  {levelCode: 'BP_4', level: '7.5% increase in base pay'},
  {levelCode: 'BP_5', level: '10% increase in base pay'},
  {levelCode: 'BP_6', level: '15% increase in base pay'},
  {levelCode: 'BP_7', level: '20% increase in base pay'},
  {levelCode: 'BP_8', level: '25% increase in base pay'},
  {levelCode: 'PTO_1', level: 'No changes to PTO'},
  {levelCode: 'PTO_2', level: '5 less days of PTO'},
  {levelCode: 'PTO_3', level: '2 less days of PTO'},
  {levelCode: 'PTO_4', level: '1 more day of PTO'},
  {levelCode: 'PTO_5', level: '2 more days of PTO'},
  {levelCode: 'PTO_6', level: '5 more days of PTO'},
  {levelCode: 'PTO_7', level: '10 more days of PTO'},
  {levelCode: 'PTO_8', level: '15 more days of PTO'},
  {levelCode: 'PTO_9', level: 'Unlimited PTO days'},
  {levelCode: 'PTO_10', level: 'Paid Sabbatical'},
  {levelCode: '4k_1', level: 'No 401k program'},
  {levelCode: '4k_2', level: '1% of annual earnings contributed by employer'},
  {levelCode: '4k_3', level: '2% of annual earnings contributed by employer'},
  {levelCode: '4k_4', level: '3% of annual earnings contributed by employer'},
  {levelCode: '4k_5', level: '4% of annual earnings contributed by employer'},
  {levelCode: '4k_6', level: '5% of annual earnings contributed by employer'},
  {levelCode: '4k_7', level: '7% of annual earnings contributed by employer'},
  {levelCode: 'WF_1', level: '5 days a week in office'},
  {levelCode: 'WF_2', level: '3 days a week in the office'},
  {levelCode: 'WF_3', level: 'Flexible working / work from home'},
  {levelCode: 'DI_1', level: 'No dental insurance (0% of a dental insurance premium is covered)'},
  {levelCode: 'DI_2', level: '50% of the premium is covered'},
  {levelCode: 'DI_3', level: '100% of the premium is covered'},
  {levelCode: 'WFP_1', level: 'No wellbeing/fitness programs'},
  {levelCode: 'WFP_2', level: 'Mental health / resilience services'},
  {levelCode: 'WFP_3', level: 'Nutrition services'},
  {levelCode: 'WFP_4', level: 'Wellbeing coaching and membership discounts'},
  {levelCode: 'WFP_5', level: '$1,000 Wellbeing subsidy (i.e. gym membership, massages...)'},
  {levelCode: 'WFP_6', level: '$2,000 Wellbeing subsidy (i.e. gym membership, massages...)'},
  {levelCode: 'WFP_7', level: '$3,000 Wellbeing subsidy (i.e. gym membership, massages...)'},
  {levelCode: 'WFP_8', level: '$5,000 Wellbeing subsidy (i.e. gym membership, massages...)'},
  {levelCode: 'TR_1', level: 'No Tuition Reimbursement'},
  {levelCode: 'TR_2', level: 'Up to $5,000 tuition reimbursement'},
  {levelCode: 'TR_3', level: 'Up to $10,000 tuition reimbursement'},
  {levelCode: 'TR_4', level: 'Up to $20,000 tuition reimbursement'},
  {levelCode: 'FPS_1', level: 'No Family Programs / Services'},
  {levelCode: 'FPS_2', level: 'Child care assistance'},
  {levelCode: 'FPS_3', level: 'Elder care assistance'},
  {levelCode: 'FPS_4', level: 'Egg freezing services'},
  {levelCode: 'FPS_5', level: 'Adoption / surrogacy stipend'},
  {levelCode: 'FPS_6', level: 'Fertility services / stipend'},
  {levelCode: 'FPS_7', level: 'New parent coaching'},
  {levelCode: 'LI_1', level: 'No Life Insurance offered'},
  {levelCode: 'LI_2', level: '1x annual salary paid by employer'},
  {levelCode: 'LI_3', level: '2x annual salary paid by employer'},
  {levelCode: 'LI_4', level: '$20,000 of life insurance paid by employer'},
  {levelCode: 'LI_5', level: '$50,000 of life insurance paid by employer'},
  {levelCode: 'LI_6', level: '$100,000 of life insurance paid by employer'},
  {levelCode: 'SD_1', level: 'Short-term disability not offered'},
  {levelCode: 'SD_2', level: 'Employer covers 60% of weekly earnings'},
  {levelCode: 'SD_3', level: 'Employer covers 100% of weekly earnings'},
  {levelCode: 'LD_1', level: 'Long-term disability not offered'},
  {levelCode: 'LD_2', level: 'Employer covers 60% of annual earnings'},
  {levelCode: 'LD_3', level: 'Employer covers 100% of annual earnings'},
  {levelCode: 'FL_1', level: 'No paid family leave'},
  {levelCode: 'FL_2', level: '1 week paid family leave (Birth, illness, injury)'},
  {levelCode: 'FL_3', level: '1 month paid family leave (Birth, illness, injury)'},
  {levelCode: 'FL_4', level: '3 months paid family leave (Birth)'},
  {levelCode: 'FL_5', level: '6 months paid family leave (Birth)'},
  {levelCode: 'OS_1', level: 'No On-site services'},
  {levelCode: 'OS_2', level: 'On-site massages'},
  {levelCode: 'OS_3', level: 'On-site fitness room'},
  {levelCode: 'OS_4', level: 'On-site mindfulness/recharge rooms'},
  {levelCode: 'OS_5', level: 'On-site health center'},
  {levelCode: 'OS_6', level: 'On-site child care center'},
  {levelCode: 'OS_7', level: 'Free lunch 3 days a week'},
  {levelCode: 'OS_8', level: 'Free lunch 5 days a week'},
  {levelCode: 'OS_9', level: 'Complimentary snacks & drinks'},
  {levelCode: 'OS_10', level: 'On-site games / activities (pool table, foosball, table tennis, video games)'},
  {levelCode: 'B_1', level: 'No annual bonus'},
  {levelCode: 'B_2', level: 'Bonus of 5% annual earnings '},
  {levelCode: 'B_3', level: 'Bonus of 10% annual earnings '},
  {levelCode: 'B_4', level: 'Bonus of 15% annual earnings '},
  {levelCode: 'B_5', level: 'Bonus of 20% annual earnings '},
  {levelCode: 'B_6', level: 'Bonus of 25% annual earnings '},
  {levelCode: 'B_7', level: 'Bonus of 50% annual earnings '},
  {levelCode: 'CSV_1', level: 'No paid volunteer time'},
  {levelCode: 'CSV_2', level: '5 paid hours per month of volunteer/charitable time'},
  {levelCode: 'CSV_3', level: '10 paid hours per month of volunteer/charitable time'},
  {levelCode: 'CSV_4', level: 'Unlimited hours of volunteer/charitable time'},
  {levelCode: 'CD_1', level: 'No career development programs'},
  {levelCode: 'CD_2', level: 'Formal mentoring program in small groups led by outside experts'},
  {levelCode: 'CD_3', level: '5 paid hours per quarter of career development '},
  {levelCode: 'CD_4', level: '10 paid hours per quarter of career development '},
  {levelCode: 'CD_5', level: '15 paid hours per quarter of career development '},
  {levelCode: 'LS_1', level: 'No legal services offered'},
  {levelCode: 'LS_2', level: 'Legal services offered after employee contributes $20 per pay period'},
  {levelCode: 'LS_3', level: 'Legal services offered (no employee contribution)'},
  {levelCode: 'VI_1', level: 'No Vision Insurance'},
  {levelCode: 'VI_2', level: 'Vision Insurance included'},
  {levelCode: 'EB_1', level: 'No Experience/Travel Bonus'},
  {levelCode: 'EB_2', level: '$1,000 Experience/Travel Bonus'},
  {levelCode: 'EB_3', level: '$3,000 Experience/Travel Bonus'},
  {levelCode: 'EB_4', level: '$5,000 Experience/Travel Bonus'},
  {levelCode: 'HID_1', level: 'No changes to Health Insurance Deductible'},
  {levelCode: 'HID_2', level: '10% increase in Health Insurance Deductible cost to employee'},
  {levelCode: 'HID_3', level: '25% increase in Health Insurance Deductible cost to employee'},
  {levelCode: 'HID_4', level: '10% decrease in Health Insurance Deductible cost to employee'},
  {levelCode: 'HID_5', level: '25% decrease in Health Insurance Deductible cost to employee'},
];
