//import { getFirestore, collection, doc, getDocs, setDoc, Bytes } from 'firebase/firestore';
//import { legacyUc } from './zz_uc';
import { blobFromStorage, ucPath } from './utils/uc';
import {defaultFeatures, defaultSubFeatures} from './zz_attr';

import { getFirestore, collection, doc, getDoc } from 'firebase/firestore';

const ucCollection = collection(getFirestore(), ucPath);

const getFC = (value) => value.split('_')[0];

const defaultFeaturesMap = defaultFeatures.reduce(
  (map, {feature, featureCode}) => ({...map, [featureCode]: feature}),
  {}
);

defaultSubFeatures.forEach((sub) => {
  sub.featureCode = getFC(sub.levelCode);
})

const defaultSubFeaturesMap = defaultSubFeatures.reduce(
  (map, {level, levelCode}) => ({...map, [levelCode]: level}),
  {}
);

const loadFeaturesFromSF = (sf) => {
  if(!sf) {
    return [ defaultFeatures, defaultFeaturesMap ]
  }

  const featuresMap = sf.reduce(
    (map, {FeatureText, FeatureCode}) => ({...map, [FeatureCode]: FeatureText}),
    {}
  );

  const features = Object.entries(featuresMap)
    .map(([featureCode, feature]) => ({feature, featureCode}));

  return [ features, featuresMap ];
}

const loadSubFeaturesFromSF = (sf) => {
  if(!sf) {
    return [ defaultSubFeatures, defaultSubFeaturesMap ]
  }

  const subfeaturesMap = sf.reduce(
    (map, {Level, LevelCode}) => ({...map, [LevelCode]: Level}),
    {}
  );

  const subfeatures = Object.entries(subfeaturesMap)
    .map(([levelCode, level]) => ({level, levelCode, featureCode: getFC(levelCode)}));

  return [ subfeatures, subfeaturesMap ];
}

const groupSubFeatures = (sf) => sf.reduce(
  (groups, subFeature) => {
    if(!groups[subFeature.featureCode]) groups[subFeature.featureCode] = [];
    groups[subFeature.featureCode].push(subFeature);
    return groups;
  }, {});

const generateFi = (uc, features) => {
  const enrichedUc = uc.slice(1).map((row) => row.map((val, ind) => ({fc: getFC(uc[0][ind]), coeff: val})))

  const fiRange = enrichedUc.map((row) => features.map(({featureCode}) => {
    const featureMatch = row.filter(({fc}) => fc === featureCode).map(({coeff}) => coeff);
    return Math.max(...featureMatch) - Math.min(...featureMatch);
  }));

  const fiDistribution = fiRange.map((row) => {
    const rowSum = row.reduce((sum, coeff) => sum + coeff, 0.0);
    return row.map((coeff) => coeff / rowSum);
  });

  let numRow = 0;
  const fiAverage = fiDistribution.reduce((rowSum, currentRow) => {
    numRow += 1;
    return currentRow.map((dist, ind) => dist + (rowSum[ind] || 0));
  }, []).map((sum, ind) => ({fc: features[ind].featureCode, avg: sum / numRow})) || [];

  fiAverage.sort((a, b) => b.avg-a.avg);

  return {
    fiRange,
    fiDistribution,
    fiAverage,
  }
}

const generatePs = (uc, subFeatures) => {
  const logitUc = uc.slice(1).map((row) => row.map((val, ind) => ({fc: getFC(uc[0][ind]), coeff: Math.exp(val)})))
  const prefShare = logitUc.map((row) => row.map(({fc, coeff: curCoeff}) => {
    const featureMatch = row.filter(({fc: fcMatch}) => fc === fcMatch).map(({coeff}) => coeff);
    const featureSum = featureMatch.reduce((sum, coeff) => sum + coeff, 0.0);
    return curCoeff / featureSum;
  }));

  let numRowPS = 0;
  const prefShareAverage = prefShare.reduce((rowSum, currentRow) => {
    numRowPS += 1;
    return currentRow.map((dist, ind) => dist + (rowSum[ind] || 0));
  }, []).map((sum, ind) => ({lc: subFeatures[ind].levelCode, avg: sum / numRowPS})) || [];

  prefShareAverage.sort((a, b) => b.avg-a.avg);

  return {
    logitUc,
    prefShare,
    prefShareAverage,
  }
}

const generateUa = (uc, subFeatures) => {
  const UCs = uc.slice(1).map((row) => row.map((val) => Number(val)));
  const numRowUA = UCs.length;
  const numColUA = UCs[0].length;
  const utilityAverage = UCs.reduce((colSum, currentRow) => {
    currentRow.map((utility, ind) => colSum[ind] += utility);
    return colSum;
  }, new Array(numColUA).fill(0))
    .map((sum, ind) => ({lc: subFeatures[ind].levelCode, avg: sum / numRowUA})) || [];

  return {
    utilityAverage,
  }
}

const getData =  async (user) => {
  if (!user) {
    console.log('Cannot load data, no user', user);
    return {};
  } else if (!(user.admin || user.role === 'read')) {
    console.log('user does not have permission', user);
  }
console.log('getting data for ', user.ucDoc)
  const ucSnapshot = await getDoc(doc(ucCollection, user.ucDoc || 'all'));
  const ucObj = (ucSnapshot.data() || []);

  const uc = blobFromStorage(ucObj.uc)
  const sf = blobFromStorage(ucObj.sf);
  const [ features, featuresMap ] = loadFeaturesFromSF(sf);
  const [ subFeatures, subFeaturesMap ] = loadSubFeaturesFromSF(sf);
  const subFeaturesByFeature = groupSubFeatures(subFeatures);
  console.log('we have', uc);
  const fi = generateFi(uc, features);
  const ps = generatePs(uc, subFeatures);
  const ua = generateUa(uc, subFeatures);
  return {
    uc,
    features,
    featuresMap,
    subFeatures,
    subFeaturesMap,
    subFeaturesByFeature,
    ...fi,
    ...ps,
    ...ua,
  };

}

export default getData;
export { getFC };