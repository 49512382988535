// material-ui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { visible } from '../../../../../utils/pageView';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
    const user = useSelector((state) => state.user)
    const navGroups = menuItem.items.filter(({visibility}) => visible[visibility](user) )
      .map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Fix - Navigation Group
                    </Typography>
                );
        }
    });

    return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
