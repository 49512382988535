// project import
// import pages from './pages';
//import dashboard from './dashboard';
// import utilities from './utilities';
//import support from './support';
import top from './top';
import reports from './reports';
import inputs from './inputs';
import admin from './admin';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [ top, reports, inputs, admin]
    // items: [dashboard, pages, utilities, support]
};

export default menuItems;
