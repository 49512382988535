import { getFirestore, collection, doc, getDoc, setDoc, updateDoc, getDocs } from 'firebase/firestore';

const userCollection = collection(getFirestore(), 'users');
const pkgCollection = collection(getFirestore(), 'packages');

const serializePlist = (plist = []) => Object.entries(plist)
  .map((plistEntry) => plistEntry.join(':'));

const deserializePlist = (plist = []) => Object.fromEntries(plist.map((ple) => ple.split(':')));

const getPackageList =  async (user) => {
  if (!user) {
    console.log('Cannot load packages, no user', user);
    return {};
  } else if (!(user.admin || user.role === 'read')) {
    console.log('user does not have permission', user);
  } else {
    console.log('Getting package list for ', user.uid)
    const packageSnapshot = await getDoc(doc(userCollection, user.uid));
    return deserializePlist(packageSnapshot.get('plist'));
  }
}

const updatePackageList = async (user, packageList) => {
  if(user) {
    const plist = serializePlist(packageList);
    await updateDoc(doc(userCollection, user.uid), {plist});
  }
}

const getPackage = async (user, pid) => {
  if (!user) {
    console.log('Cannot load packages, no user', user);
    return {};
  } else if (!(user.admin || user.role === 'read')) {
    console.log('user does not have permission', user);
  } else {
    console.log(`Getting package ${pid}`)
    const packageSnapshot = await getDoc(doc(pkgCollection, pid));
    return packageSnapshot.data() || {};
  }
}

const getPackages = async (user) => {
  if (!user) {
    console.log('Cannot load packages, no user', user);
    return {};
  } else if (!(user.admin || user.role === 'read')) {
    console.log('user does not have permission', user);
  } else {
    const userPids = deserializePlist(user?.plist)
    console.log(`Getting packages ${userPids}`);
    if(userPids.length > 0) {
      const docs = userPids.map((pid) => doc(pkgCollection, pid));
      const packageSnapshot = await getDocs(docs);
      console.log('got these packages: ', packageSnapshot)
      return packageSnapshot.data() || [];
    }
    return [];
  }
}

const getCurrentPackage = async (user, data) => {
  if (!user) {
    console.log('Cannot load packages, no user', user);
    return {};
  } else if (!(user.admin || user.role === 'read')) {
    console.log('user does not have permission', user);
  } else {
    const currentPackagePid = (deserializePlist(user?.plist)).current;
    if(!currentPackagePid) {
      // The user doesn't have a saved packagelist with their current benefit offering
      // Default them to the zeroth subfeaturecode
      return Object.entries(data.subFeaturesByFeature)
        .reduce((pkg, [fc, lcArr]) => ({...pkg, [fc]: lcArr[0].levelCode}), {});
    }
    const currentPkg = await getPackage(user, currentPackagePid);
    return currentPkg;
  }
}

const createPid = () => doc(pkgCollection).id;

const updatePackage = async (user, pkg, pid) => {
  if(user.admin || user.role === 'read') {
    try {
      const savedPkg = await setDoc(doc(pkgCollection, pid), pkg);
      return savedPkg;
    } catch (e) {
      console.error('Error while saving package:', e);
    }
  }
  return {};
}

export {
  createPid,
  getPackages,
  getPackage,
  getCurrentPackage,
  updatePackage,
  getPackageList,
  updatePackageList,
};