// material-ui
// import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    // const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Mantis" width="100" />
         *
         */
        <>
            <svg width="35" height="35" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="m 1.032145,2.916955 c 1.261529,-0.754641 4.043156,-1.667892 6.7744,-1.992199 3.112152,-0.388835 6.417899,-0.557752 9.348138,0.761415 1.827277,0.688954 3.670801,2.064849 3.858894,4.158259 0.394232,2.64127 -1.495883,5.05989 -3.691279,6.287 -0.581317,0.39214 -2.271709,0.46267 -2.266879,0.77664 0.996294,0.39339 2.076529,0.79505 2.615038,1.80259 1.059366,1.49227 0.679596,3.49614 -0.298051,4.92142 -1.290492,1.93876 -3.540128,3.02837 -5.742336,3.58434 -2.072192,0.4426 -4.767421,0.40096 -6.273277,0.21543 -1.505856,-0.18554 -0.06785,1.10754 -2.164007,1.1162 -2.09616,0.009 -2.618348,0.33367 -2.343067,-0.98705 0.39635,-3.67165 1.092938,-7.53949 1.771567,-11.17078 0.426667,-1.83601 0.72896,-3.59468 1.552405,-5.31315 0.794883,-0.90887 1.85919,-0.88407 2.93356,-0.51464 1.059154,0.79679 0.137963,2.13283 -0.242678,4.31675 -0.380641,2.18391 -1.338485,4.86305 -1.752034,7.85968 -0.413549,2.99663 3.490997,1.81674 4.443345,1.63071 0.952347,-0.18603 2.122463,-0.50558 3.196187,-1.28115 1.073724,-0.77556 1.181463,-1.06805 1.155799,-2.63512 -0.205297,-1.44998 -1.801504,-2.26229 -2.98855,-2.2776 -1.187046,-0.0153 -2.299193,0.23143 -2.250777,-1.63051 0.04842,-1.86194 1.60657,-1.54905 2.668151,-1.64443 1.927475,0.1195 4.073936,-0.61192 4.997438,-2.42635 0.863505,-1.37491 0.106875,-3.32762 -1.409712,-3.84572 -2.243851,-0.79835 -4.716086,-0.77316 -7.043691,-0.40131 -2.310466,0.62843 -4.581727,1.20632 -6.718109,2.33034 -1.544125,0.838 -2.486768,0.33112 -2.846172,-0.8578 -0.216934,-1.35323 1.454167,-2.02832 2.715697,-2.782965 z"
                    fill="#1789fb"
                />
            </svg>
        </>
    );
};

export default Logo;
