import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';

// render - inputs
const Filters = Loadable(lazy(() => import('pages/inputs/Filters')));
const CostInputs = Loadable(lazy(() => import('pages/inputs/CostInputs')));
const Current = Loadable(lazy(() => import('pages/inputs/Current')));

// ==============================|| MAIN ROUTING ||============================== //

const InputRoutes = {
  path: 'inputs',
  children: [
    {
      path: 'filters',
      element: <Filters/>
    },
    {
      path: 'cost-inputs',
      element: <CostInputs/>
    },
    {
      path: 'current',
      element: <Current/>
    },
  ]
};

export default InputRoutes;
