// assets
import { GiftOutlined, UserOutlined, AntDesignOutlined } from '@ant-design/icons';
import {ADMIN} from '../utils/pageView';

// icons
const icons = {
  GiftOutlined,
  UserOutlined,
  AntDesignOutlined,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const inputs = {
  id: 'group-admin',
  title: 'Admin',
  visibility: ADMIN,
  type: 'group',
  children: [
    {
      id: 'utility-coeff',
      title: 'Utility Coefficients',
      type: 'item',
      url: '/admin/utility-coeff',
      icon: icons.GiftOutlined,
      breadcrumbs: false
    },
    {
      id: 'users',
      title: 'Users',
      type: 'item',
      url: '/admin/users',
      icon: icons.UserOutlined,
      breadcrumbs: false
    },
    {
      id: 'ant-icons',
      title: 'Ant Icons',
      type: 'item',
      url: '/icons/ant',
      icon: icons.AntDesignOutlined,
      breadcrumbs: false
    }
  ]
};

export default inputs;
