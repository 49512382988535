// assets
import { PieChartOutlined, DotChartOutlined, StockOutlined, DollarOutlined } from '@ant-design/icons';
import {ALL} from '../utils/pageView';

// icons
const icons = {
  PieChartOutlined,
  DotChartOutlined,
  StockOutlined,
  DollarOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const reports = {
  id: 'group-reports',
  title: 'Reports',
  visibility: ALL,
  type: 'group',
  children: [
    {
      id: 'importance',
      title: 'Benefit Categories Importance',
      type: 'item',
      url: '/reports/importance',
      icon: icons.PieChartOutlined,
      breadcrumbs: false
    },
    {
      id: 'preference-share',
      title: 'Complete Preference Shares',
      type: 'item',
      url: '/reports/preference-share',
      icon: icons.DotChartOutlined,
      breadcrumbs: false
    },
    {
      id: 'focus',
      title: 'Benefit Focus',
      type: 'item',
      url: '/reports/focus',
      icon: icons.StockOutlined,
      breadcrumbs: false
    },
    {
      id: 'cost-analysis',
      title: 'Cost Analysis',
      type: 'item',
      url: '/reports/cost-analysis',
      icon: icons.DollarOutlined,
      breadcrumbs: false
    }
  ]
};

export default reports;
