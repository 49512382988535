//import { getFirestore, collection, doc, getDocs, setDoc, Bytes } from 'firebase/firestore';
//import { legacyUc } from './zz_uc';
import { blobFromStorage, blobToStorage } from './utils/uc';

import { getFirestore, collection, doc, getDoc, updateDoc } from 'firebase/firestore';

const userCollection = collection(getFirestore(), 'users');

const getCosts =  async (user) => {
  if (!user) {
    console.log('Cannot load costs, no user', user);
    return {};
  } else if (!(user.admin || user.role === 'read')) {
    console.log('user does not have permission', user);
  } else {
    console.log('getting costs for ', user.uid)
    const costSnapshot = await getDoc(doc(userCollection, user.uid));
    const costs = costSnapshot.get('costs');

    return costs ? blobFromStorage(costs) : {};
  }
}

const parseCosts = (costs) => blobFromStorage(costs);

const saveCosts = async (user, costs) => {
  if(user) {
    await updateDoc(doc(userCollection, user.uid), {costs: blobToStorage(costs)});
  }
}

export default parseCosts;
export { getCosts, saveCosts };