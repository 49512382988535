// types
import { createSlice } from '@reduxjs/toolkit';
import { saveCosts } from '../../costs';

// initial state
const initialState = {
  costsLoaded: false,
  costsLoading: true,
  costs: {},
};

// The costs look like this:
//  {
//    B: { B_1: "0", B_2: "1000" },
//    HIP: { HIP_1: "0", HIP_2: "1500" },
//    x4k: { x4k_1: "0", x4k_2: "750" },
//  }

// ==============================|| SLICE - MENU ||============================== //

const cost = createSlice({
  name: 'cost',
  initialState,
  reducers: {
    loadCosts(state, action) {
      console.log('we have an attempt to load costs', action.payload)
      state.costsLoaded = true;
      state.costsLoading = false;
      state.costs = {...action.payload};
      return state;
    },
    loadingCosts(state) {
      state.costsLoading = true;
      return state;
    },
    loadAllCosts(state, action) {
      state.needsSaving = true;
      state.costs = { ...action.payload };
      return state;
    },
    loadFeatureCosts(state, action) {
      console.log('loading costs', action.payload)
      state.costs[action.payload.cat] = {
        ...(state.costs[action.payload.cat] || {}),
        ...action.payload.costs
      };
      state.needsSaving = true;
      return state;
    },
    saveFeatureCosts(state, action) {
      if(state.needsSaving) {
        console.log('saving!', state, action);
        state.needsSaving = false;
        saveCosts(action.payload, { ...state.costs })
      }
      return state;
    }
  }
});

export default cost.reducer;

export const {
  loadCosts,
  loadingCosts,
  loadAllCosts,
  loadFeatureCosts,
  saveFeatureCosts,
} = cost.actions;
