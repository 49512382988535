import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';

// render - dashboard
const Importance = Loadable(lazy(() => import('pages/reports/Importance')));
const PreferenceShare = Loadable(lazy(() => import('pages/reports/PreferenceShare')));
const Focus = Loadable(lazy(() => import('pages/reports/Focus')));
const CostAnalysis = Loadable(lazy(() => import('pages/reports/CostAnalysis')));

// ==============================|| MAIN ROUTING ||============================== //

const ReportRoutes = {
  path: 'reports',
  children: [
    {
      path: 'importance',
      element: <Importance/>
    },
    {
      path: 'preference-share',
      element: <PreferenceShare/>
    },
    {
      path: 'focus',
      element: <Focus/>
    },
    {
      path: 'cost-analysis',
      element: <CostAnalysis/>
    }
  ]
};

export default ReportRoutes;
