// material-ui
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

// project import
import Search from './Search';
import Profile from './Profile';
import Notification from './Notification';
import MobileSection from './MobileSection';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

    return (
        <>
            {!matchesXs && <Search />}
            {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}

            <Notification />
            {!matchesXs && <Profile />}
            {matchesXs && <MobileSection />}
        </>
    );
};

export default HeaderContent;

// <IconButton
//   component={Link}
//   href="https://github.com/codedthemes/mantis-free-react-admin-template"
//   target="_blank"
//   disableRipple
//   color="secondary"
//   title="Download Free Version"
//   sx={{ color: 'text.primary', bgcolor: 'grey.100' }}
// >
//   <GithubOutlined />
// </IconButton>